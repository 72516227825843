import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Image } from 'src/containers/Image2';
import { Grid, Column } from 'src/components/molecules/Grid';

export default function Drawer() {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "research_logos.png" }) {
        ...Image2
      }
    }
  `);

  return (
    <Section centered>
      <SectionHeader>
        <h2>Academic Collaborations</h2>
      </SectionHeader>

      <Grid columns="1fr 1fr 1fr">
        <Column>
          <h3>Research</h3>
          <p>
            Our open source tools are used in research projects and papers at a growing number of{' '}
            <a href="https://scholar.google.de/scholar?oi=bibs&amp;hl=de&amp;cites=16243802403383697687">
              leading institutions
            </a>
            . In addition, <a href="http://www.aclweb.org/anthology/W17-5522">TU Munich</a> published a study
            benchmarking our NLU system.
          </p>
        </Column>
        <Column>
          <h3>Partnerships</h3>
          <p>
            We’re a partner of the{' '}
            <a href="https://edinburghnlp.inf.ed.ac.uk/cdt/">
              UKRI Centre for Doctoral Training in Natural Language Processing
            </a>{' '}
            at the University of Edinburgh. We are also sponsors of{' '}
            <a href="https://www.sigdial.org/files/workshops/conference21/">SigDIAL 2020</a>.
          </p>
        </Column>
        <Column>
          <h3>Education</h3>
          <p>
            Every year we supervise a few MSc students and take on some interns to work on research. If you
            are using Rasa in a course, get in touch and we can share materials for use in lectures and group
            projects.
          </p>
        </Column>
      </Grid>

      <Image
        data={data.logo}
        alt="List of the collaborating institutions; TU Munich, Johns Hopkins University, TU Delft, Heriot Watt University, The University of Edinburgh"
        width="395"
        centered
      />
    </Section>
  );
}
